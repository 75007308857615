.App {
    background-color: white;
    font-size: calc(12px + 1.5vmin);
    color: #282c34;
    font-family: "andale-mono-local", serif;
}

@font-face {
    font-family: 'andale-mono-local';
    src: local('andale-mono-local'), url(font/andale-mono.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.Background {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
}

.Background-sky {
    display: flex;
    min-height: 62vh;
    width: 100%;
    background: linear-gradient(0deg, rgba(87, 154, 263, 1) 0%, rgba(44, 110, 217, 1) 100%);
}

.Background-sea {
    display: flex;
    min-height: 8vh;
    width: 100%;
    background: linear-gradient(0deg, rgba(57, 196, 219, 1) 0%, rgba(39, 101, 172, 1) 100%);
    filter: blur(0.1vh);
}

.Background-beach {
    display: flex;
    min-height: 30vh;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 250, 228, 1) 0%, rgba(241, 246, 230, 1) 100%);
}

.Circle {
    height: 40vmin;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 10vh;
}

.Foreground {
    position: fixed;
    top: 22%;
    right: 10%;
    bottom: 10%;
    left: 10%;
    max-width: 500px;
    border: #282c34 solid;
    border-radius: 4vh;
    background-color: rgba(241, 246, 230, 1);
    opacity: 0.8;
    color: rgba(39, 101, 172, 1);
    overflow-y: auto;
    box-shadow: -5px 5px #888888;
}

.Foreground h1 {
    text-align: center;
}

.Foreground p {
    padding-left: 1em;
}

.Hide{
    display: none;
}
.Heading{
    position: fixed;
    left: 10%;
}
.Heading h2{
    font-style: italic;

}

.Buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 2vh;
}

.Button {
    display: flex;
    width: 27%;
    height: 10vh;
    text-align: center;
    align-content: center;
    justify-content: center;
    horiz-align: center;
    background-color: rgba(39, 101, 172, 1);
    border: #282c34 solid;
    border-radius: 4vh;
    color: rgba(241, 246, 230, 1);
    font-size: calc(5px + 8vh);
    opacity: 0.8;
    cursor: pointer;

}
.Cursor-Pointer{
    cursor: pointer;
}
.Button:hover {
    opacity: 1.0;
    transform: translateY(1px);
}

.Button:active {
    background-color: #282c34;
    transform: translateY(4px);
}

.External-logos {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.Logo-fb {
    height: 96px;
}

.Profile-Picture {
    position: fixed;
    left: 50%;
    bottom: 10%;
    max-width: 500px;
    width: 7em;
}

.Profile-Picture-Gone {
    position: fixed;
    left: 50%;
    bottom: 10%;
    max-width: 500px;
    width: 7em;
}

.Techstack {
    text-align: center;
}

.Techstack a:link {
    color: greenyellow
}

.Techstack a:visited {
    color: greenyellow
}

.Techstack a:hover {
    color: green;
}

.Techstack a:active {
    color: greenyellow
}

.Typewriter h1{
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}
@media (prefers-reduced-motion: no-preference) {
    .Circle {
        animation: Circle-move infinite 20s linear;
    }

    .Profile-Picture {
        animation: Picture-move 2s forwards;
    }

    .Profile-Picture-Gone {
        animation: Picture-hide 1s forwards;
    }

    .Foreground {
        animation: Fade-in 1s forwards;
    }

    .Techstack {
        animation: Make-Dark 1s forwards;
    }

    .Typewriter h1{
        animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
    }
    .Typewriter p h2{
        animation: Fade-in-slow 5s forwards;
    }
}

@keyframes Circle-move {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes Picture-move {
    from {
        transform: rotate(0deg);
        left: 0;
    }
    to {
        transform: rotate(360deg);
        left: 50%;
    }
}

@keyframes Picture-hide {
    from {
        transform: rotate(0deg);
        left: 50%;
    }
    to {
        transform: rotate(360deg);
        left: 100%;
    }
}

@keyframes Fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}
@keyframes Fade-in-slow {
    0% {
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 0.8;
    }
}
@keyframes Make-Dark {
    to {
        background-color: #282c34;
        color: greenyellow;
    }
}

@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: orange;
    }
}
